.weather-widget {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  text-shadow: 0 0 3px rgb(0, 0, 0);
  font-size: 2rem;
  /* max-width: 250px; */
}

.temp {
  display: flex;
  padding: 0;
  justify-content: flex-end;
}

.temp p {
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: clip; */
}

.weather-widget img {
  height: 100%;
  margin: auto 0;
  max-width: 35px;
  max-height: 35px;
}

.weather-widget p {
  margin: auto 0;
}


.weather-error-message {
  font-size: 1rem;
}

.weather-error-message a {
  color: var(--link-color);
  text-shadow: none;
}

.temp-description {
  font-size: 1rem;
  line-height: 90%;
  text-align: right;
}


@media only screen and (max-width: 600px) {
  .weather-widget {
    top: 5px;
    right: 5px;
    font-size: 1rem;
  }

  .temp-description,
  .weather-error-message {
    font-size: 0.75rem;
  }
}