.modal,
.overlay {
  display: block;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.overlay {
  background-color: rgba(50, 50, 50, 0.8);
}

.modal-container {
  background-color: rgba(119, 117, 117, .9);
  margin: 10vh auto;
  padding: 2rem;
  width: 80vw;
  min-height: 10vh;
  max-height: 70vh;
  overflow: auto;
  position: relative;
  z-index: 10;
  animation: modal .5s 1 cubic-bezier(.4,0,.2,1);
}

@keyframes modal {
  0% { transform:  scale(0.8); }
  100% { transform:  scale(1); }
}

.close {
  color: rgb(255, 255, 255);
  float: right;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-container ul {
  padding: 0;
  list-style-type: none;
}

.locations-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.locations-list li {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem 0 0;
}