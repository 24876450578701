.auth-widget {
    position: absolute;
    top: 10px;
    left: 15px;
    color: white;
    text-shadow: 0 0 3px rgb(0, 0, 0);
    font-size: 2rem;
}

.auth-widget p {
    font-size: 1rem;
    line-height: 90%;
    text-align: left;
    margin: 0.3rem 0;
}

.login-form p {
    margin-bottom: 0;
    color: white;
}

.login-form .white-text-input {
    padding-top: 0.3rem;
}

.login-form .white-button {
    margin-top: 1rem;
}

#sign-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#sign-container a,
.auth-widget a {
    color: var(--link-color);
    text-shadow: none;
}

p.login-error-msg {
    color:rgb(249, 163, 163)
}

#googleBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
  }
  #googleBtn:hover {
    cursor: pointer;
  }
  span.label {
    font-family: serif;
    font-weight: normal;
  }
  span.google-icon {
    background: url('../../../public/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }
  span.google-buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
  }
  .customGPlusSignIn {
    margin-top: 1rem;
  }

@media only screen and (max-width: 600px) {
    .auth-widget {
        top: 5px;
        left: 5px;
        font-size: 1rem;
    }
    .auth-widget p {
        font-size: 0.7rem;
    }
}